@media screen and (max-width: 700px) and (min-width: 0px) {

.compare_button {
    outline: none;
    border: none;
    color: white;
    border-radius: 100px;
    background-color: #3477cc;
    text-transform: none;
    transition: none;
    font-weight: bold;
}

.compare_button:hover {
    outline: none;
    border: none;
    color: white;
    border-radius: 100px;
    background-color: #3477cc;
    text-transform: none;
    transition: none;
    font-weight: bold;
}

.close_button {
    background-color: transparent;
    border: none;
    outline: none;
}

}

@media screen and (max-width: 1000px) and (min-width: 700px) {

.compare_button {
    outline: none;
    border: none;
    color: white;
    border-radius: 100px;
    background-color: #3477cc;
    text-transform: none;
    transition: none;
    font-weight: bold;
}

.compare_button:hover {
    outline: none;
    border: none;
    color: white;
    border-radius: 100px;
    background-color: #3477cc;
    text-transform: none;
    transition: none;
    font-weight: bold;
}

.close_button {
    background-color: transparent;
    border: none;
    outline: none;
}

}

@media screen and (max-width: 1200px) and (min-width: 1000px) {

.compare_button {
    outline: none;
    border: none;
    color: white;
    border-radius: 100px;
    background-color: #3477cc;
    text-transform: none;
    transition: none;
    font-weight: bold;
}

.compare_button:hover {
    outline: none;
    border: none;
    color: white;
    border-radius: 100px;
    background-color: #3477cc;
    text-transform: none;
    transition: none;
    font-weight: bold;
}

.close_button {
    background-color: transparent;
    border: none;
    outline: none;
}

}

@media screen and (min-width: 1200px) {

.compare_button {
    outline: none;
    border: none;
    color: white;
    border-radius: 100px;
    background-color: #3477cc;
    text-transform: none;
    transition: none;
    font-weight: bold;
}

.compare_button:hover {
    outline: none;
    border: none;
    color: white;
    border-radius: 100px;
    background-color: #3477cc;
    text-transform: none;
    transition: none;
    font-weight: bold;
}

.compare_checkbox {
    box-shadow: none;
    background-color: transparent;
}

.compare_checkbox:hover {
    box-shadow: none;
    background-color: transparent;
}

.compare_checkbox:checked {
    color: 'red';
    background-color: transparent;
}

.close_button {
    background-color: transparent;
    border: none;
    outline: none;
}

}