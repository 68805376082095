@media screen and (max-width: 700px) and (min-width: 0px) {
  .body {
    background-color: rgb(242, 242, 242);
    /* display: flex; */
    width: 98%;
    /* margin-right: 2%; */
    margin-left:  1%;
  }
  .feed {
    z-index: 1;
    position: relative;
    top: 55px;
  }

  .side_bar {
    display: none;
  }


}


@media screen and (max-width: 1000px) and (min-width: 700px) {
  .body {
    background-color: rgb(242, 242, 242);
    display: flex;
    /* width: 60%; */
    /* margin-right: 10%; */
    margin-left: 2%;
  }

    .feed {
      align-items: flex-start;
      width: 60%;
      /* margin-left: 5%; */
      z-index: 1;
      position: relative;
      top: 70px;
    }

    .side_bar {
      align-items: flex-start;
      width: 30ch;
      margin-left: 1%;
      z-index: 1;
      position: relative;
      top: 70px;
    }

}

@media screen and (max-width: 1200px) and (min-width: 1000px) {
  .body {
    background-color: rgb(242, 242, 242);
    display: flex;
    /* width: 60%; */
    /* margin-right: 10%; */
    margin-left: 2%;
  }

  .feed {
    align-items: flex-start;
    width: 70ch;
    /* margin-left: 10%; */
    z-index: 1;
    position: relative;
    top: 70px;
  }

  .side_bar {
    align-items: flex-start;
    width: 30ch;
    margin-left: 1%;
    z-index: 1;
    position: relative;
    top: 70px;
  }

}



@media screen and (min-width:1200px) {
  .body {
    background-color: rgb(242, 242, 242);
    display: flex;
    /* width: 50%; */
    /* margin-right: 10%; */
    margin-left: 2%;
    padding-bottom: 150px;
  }
  
  .feed {
    align-items: flex-start;
    width: 70ch;
    margin-left: 20%;
    z-index: 1;
    position: relative;
    top: 70px;
  }

  .side_bar {
    align-items: flex-start;
    width: 30ch;
    margin-left: 30px;
    z-index: 1;
    position: relative;
    top: 70px;
  }
}


